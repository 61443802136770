//账号列表
export const bankCols = [
  {
    prop: 'bankCategory',
    label: '银行分类',
    minWidth: '200'
  },
  {
    label: '开户银行',
    minWidth: '200'
  },
  {
    label: '时间',
    minWidth: '200',
    slotName: 'timeSlot'
  }
]
